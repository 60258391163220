import styled, { keyframes } from "styled-components"
import { TransitionGroup } from "react-transition-group"
import { typography, layout, space, color, flexbox } from "styled-system"
import Grid from "./grid"
import Text from "./text"

export const Container = styled(Grid.Item)``
Container.defaultProps = {
  gridColumn: "inqury-form-start / inqury-form-end",
  order: [2, 2, 1],
}

export const Form = styled.form`
  display: flex;
  flex-flow: column;
`

export const Field = styled.input`
  ${typography}
  ${space}
  ${layout}
  ${flexbox}
  ${color}

  box-sizing: border-box;
  border: 2px solid;
  border-color: ${(props) =>
    props.error ? props.theme.colors.error : props.theme.colors.black[2]};
  border-radius: 0;
  outline: none;
  appearance: none;

  /* &:hover {
    border-color: ${(props) => props.theme.colors.black[0]};
  } */
`

Field.defaultProps = {
  fontFamily: "mono",
  fontSize: ["mobile.-1", "desktop.-1"],
  borderColor: "black.0",
  mb: ["y.mobile.0.5", "y.desktop.0.5"],
  pl: "x.2",
  height: ["heights.mobile.2.0", "heights.desktop.2.0"],
}

export const Textarea = styled.textarea`
  ${typography}
  ${space}
  ${layout}
  ${flexbox}
  ${color}

  box-sizing: border-box;
  border: 2px solid;
  border-color: ${(props) =>
    props.error ? props.theme.colors.error : props.theme.colors.black[0]};
  border-radius: 0;
  outline: none;
  appearance: none;
`

Textarea.defaultProps = {
  fontFamily: "mono",
  fontSize: ["mobile.-1", "desktop.-1"],
  lineHeight: ["mobile.1.0", "desktop.1.0"],
  borderColor: "black.0",
  mb: ["y.mobile.1.5", "y.desktop.1.5"],
  pt: ["y.mobile.0.5", "y.desktop.0.5"],
  pl: "x.2",
  height: ["heights.mobile.8.0", "heights.desktop.8.0"],
}

const highlight = keyframes`
  from {
    transform: scale(0);
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

const textColor = keyframes`
  from {
    color: #fff;
  }
  to {
    color: #000;
  }
`

export const Controls = styled(TransitionGroup)`
  ${layout}
`
Controls.defaultProps = {
  height: ["5.2rem"],
}

export const Button = styled.button`
  ${typography}
  ${space}
  ${layout}
  ${flexbox} 
  ${color}
  
  position: absolute;
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  transform: perspective(1px) translate3d(0, 0, 0);
  transition: box-shadow 300ms ease;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  &::before {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem;
    z-index: -1;
    background: #fff;
    opacity: 0;
    content: "";
  }

  &:active {
    color: #000;

    &::before {
      transform: scale(1);
      opacity: 1;
    }
  }

  &:hover:enabled {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    animation: 300ms ease-out forwards ${textColor};

    &::before {
      animation: 300ms ease-out forwards ${highlight};
    }
  }

  &.switch-enter {
    transform: translate3d(-2.4rem, 0, 0);
    opacity: 0;
  }
  &.switch-enter-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &.switch-exit {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  &.switch-exit-active {
    transform: translate3d(2.4rem, 0, 0);
    opacity: 0;
  }

  &.switch-enter-active,
  &.switch-exit-active {
    transition: opacity 150ms ease-in, transform 150ms ease-in;
  }
`

Button.defaultProps = {
  fontFamily: "mono",
  fontSize: ["mobile.0", "desktop.1"],
  lineHeight: ["mobile.1.0", "desktop.1.0"],
  bg: "black.0",
  px: "x.3",
  py: "1.2rem",
  color: "white",
  alignSelf: "flex-start",
}

export const Success = styled(Text)`
  position: absolute;
  transform-origin: left;

  &.switch-enter {
    transform: translate3d(-2.4rem, 0, 0);
    opacity: 0;
  }
  &.switch-enter-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &.switch-exit {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  &.switch-exit-active {
    transform: translate3d(2.4rem, 0, 0);
    opacity: 0;
  }
  &.switch-enter-active,
  &.switch-exit-active {
    transition: opacity 350ms ease-in-out 100ms,
      transform 350ms ease-in-out 100ms;
  }
`
Success.defaultProps = {
  fontSize: ["mobile.0", "desktop.0"],
  color: "success",
}
