import React from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { Container, Text } from "./contact-hero.styles"
import Grid from "./grid"

export default ({ data }) => {
  return (
    <Container>
      <Grid.Item gridColumn="h-start / h-end" alignSelf="center">
        <Text>{data.intro.text}</Text>
      </Grid.Item>

      <Grid.Item gridColumn="h-start / h-end" alignSelf="flex-end">
        <RichText
          render={data.contact_info.raw}
          htmlSerializer={htmlSerializer({
            fontFamily: "mono",
            fontSize: ["mobile.-1", "desktop.-1"],
            color: "black.7",
          })}
          linkResolver={linkResolver}
        />
      </Grid.Item>
    </Container>
  )
}
