import React, { useState } from "react"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../utils/linkResolver"
import htmlSerializer from "../utils/htmlSerializer"
import { Container } from "./contact-inquiry-info.styles"

export default ({ data }) => (
  <Container>
    <RichText
      render={data.raw}
      htmlSerializer={htmlSerializer()}
      linkResolver={linkResolver}
    />
  </Container>
)
