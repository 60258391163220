import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { CSSTransition } from "react-transition-group"

import {
  Container,
  Form,
  Field,
  Textarea,
  Button,
  Controls,
  Success,
} from "./contact-inquiry-form.styles"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default () => {
  const [submited, setSubmited] = useState(false)

  const {
    values,
    errors,
    isValid,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      subject: Yup.string()
        .max(75, "Must be 75 characters or less")
        .required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        await axios({
          url: "/",
          method: "post",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: encode({ "form-name": "contact", ...values }),
        })
        setSubmited(true)
      } catch (error) {
        alert("Could not send your message. Please try again later.")
      }
    },
  })

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />
        <Field
          id="name"
          name="name"
          type="text"
          placeholder="Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          error={errors.name}
        />

        <Field
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={errors.email}
        />

        <Field
          id="subject"
          name="subject"
          type="text"
          placeholder="Betreff"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.subject}
          error={errors.subject}
        />

        <Textarea
          id="message"
          name="message"
          placeholder="Deine Nachricht"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.message}
          error={errors.message}
        />

        <Controls>
          <CSSTransition
            key={submited ? "submit" : "success"}
            timeout={450}
            classNames="switch"
          >
            {submited ? (
              <Success>Vielen Dank für Ihre Nachricht!</Success>
            ) : (
              <Button
                type="submit"
                disabled={!isValid || isSubmitting ? "disabled" : ""}
              >
                Senden
              </Button>
            )}
          </CSSTransition>
        </Controls>
      </Form>
    </Container>
  )
}
