import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import { withPreview } from "gatsby-source-prismic"

import SEO from "../components/seo"
import Text from "../components/text"
import Grid from "../components/grid"
import Hero from "../components/video-hero"
import ContactHero from "../components/contact-hero"
import ScrollIndicator from "../components/scroll-indicator"
import ContactInquiryForm from "../components/contact-inquiry-form"
import ContactInquiryInfo from "../components/contact-inquiry-info"
import PageWrapper from "../components/page-wrapper"

export const query = graphql`
  query Contact {
    site {
      siteMetadata {
        title
      }
    }

    allPrismicContact {
      edges {
        node {
          prismicId
          data {
            intro {
              text
            }
            contact_info {
              raw
            }
            inquiry_info {
              raw
            }
          }
        }
      }
    }
  }
`

const ContactPage = (props) => {
  const page = get(props, `data.allPrismicContact.edges[0].node.data`)
  if (!page) return null

  return (
    <PageWrapper name="contact">
      <SEO title="Contact" />
      <Hero publicId="contact_zmfo0g">
        <ContactHero data={page} />
      </Hero>
      <Grid>
        <ScrollIndicator />
        <Grid.Item gridColumn="intro-start / intro-end">
          <Text.H3 mt={["mobile.y.1.0", "desktop.y.0.0"]}>Contact</Text.H3>
        </Grid.Item>
        <ContactInquiryForm />
        <ContactInquiryInfo data={page.inquiry_info} />
      </Grid>
    </PageWrapper>
  )
}

export default withPreview(ContactPage)
